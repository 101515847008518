
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "modalitas-belajar-mrk",
  components: {
  },
  setup() {
    var participantCache = ref([] as Array<any>)
    var graphCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_MODALITAS_BELAJAR
    let isDialog = ref(false)
    let itemsDialog = ref({})
    var keyList = ref([] as Array<any>)
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-warning");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const series = ref([] as Array<any>);
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [] as Array<any>,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("MODALITAS BELAJAR", [])
      loadData()
    })

    async function loadData(){
      participantCache.value = []
      await Services.GetData(ApiService2, `result/tipetest`, TypeTestID, response=>{
          participantCache.value = response.data
      }, err =>{
        err
      })
    }

    async function openModal(item){
      Promise.all([await getResult(item), await getModalitasBelajar()]).then((values) => {
        mappingChart()
        isDialog.value = true
      });
    }

    async function getResult(item){
      graphCache.value = []
      await Services.GetData(ApiService2, `result/tokenandpartisipantmodalitasbelajar`, item.session+'/'+ item.participant_id, response=>{
          itemsDialog.value = item
          graphCache.value = response.data
      }, err =>{
        err
      })
    }

    async function getModalitasBelajar(){
      var modalitasBelajarExampleCache: any[] = [];
      await Services.GetData(ApiService2, `master/questions-modalitas-belajar/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          if(response.data[i]['IsExample'] == 1)         
            modalitasBelajarExampleCache.push(response.data[i])
        }
        createBox(modalitasBelajarExampleCache)
      }, err =>{
        err
      })
    }

    function createBox(data){
      var foundObj = data.find((element) => element.KeyList != "")
      var optionsBoxsString = foundObj.KeyList
      var optionsBoxsArr = optionsBoxsString.split(",")

      keyList.value = optionsBoxsArr
    }

    function mappingChart(){
      series.value = []       
      var opt = options.value
      opt.xaxis.categories = []
      var dataNew : any[] = [];
      for (var i = 0; i < keyList.value.length; i++) {
        var keylistUp = keyList.value[i].toUpperCase()
        opt.xaxis.categories.push('Jawaban: '+ keylistUp)

        var tot = 0
        let objResult = graphCache.value.findFirstUsingEquality((obj)=>{
          return obj.answer_content == keylistUp
        })

        if(objResult != undefined)
          tot = objResult.answer_total

       dataNew.push(tot)
      }
      var obj = {name: "Total", data:dataNew}
      series.value.push(obj)
    }

    function closeModal(){
      isDialog.value = false
      itemsDialog.value = {}
    }

    return {
      participantCache,
      graphCache,
      openModal,
      closeModal,
      isDialog,
      itemsDialog,
      options,
      series,
    };
  },
});
